import { GraphQLError } from 'graphql';
// Operation and timestamp is in json string format, parse the json to get objects
export interface Job {
    operation : string;  // Type Operation
    jobId: string;
    status : JobStatus;
    error? : GraphQLError[];  // Type : GraphQLError
    timestamp : string;  // Type : Date
  }
  

export enum JobStatus {
  success = 'success',
  failed = 'failed',
  pending = 'pending'
}