import { Injectable } from '@angular/core';
import { FetchResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { FindAllGroupPermissionsRequest, GroupMember, GroupPermission } from '@ih/app/shared/apis/interfaces';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable, take } from 'rxjs';

@Injectable()
export class GroupsService {
  constructor(private readonly apollo: Apollo, private readonly store: Store) {}

  findAllGroupMembersWithRolePermissions(
    userId: string,
    groupId: string
  ): Observable<GroupMember[]> {
    type ResultType = { findAllGroupMembers: GroupMember[] | null };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllGroupMembers($userId: String!, $groupId: String!) {
        findAllGroupMembers(
          request: { where: { userId: $userId, groupId: $groupId } }
        ) {
          role
          groupId
          groupRole {
            id
            name
            groupRolePermissions {
              id
              groupPermissionId
            }
          }
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          userId,
          groupId,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }

            return result.data?.findAllGroupMembers || [];
          }
        )
      );
  }

  findAllGroupPermissions(
    request: FindAllGroupPermissionsRequest
  ): Observable<GroupPermission[]> {
    type ResultType = { findAllGroupPermissions: GroupPermission[] | null };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllGroupPermissions($request: FindAllGroupPermissionsRequest!) {
        findAllGroupPermissions(request: $request) {
          id
          identifier
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }

            return result.data?.findAllGroupPermissions || [];
          }
        )
      );
  }
}
