import { Injectable } from '@angular/core';
import { GroupState } from '@ih/app/client/group/data-access';
import {
  GroupsService,
  OrganisationsService,
  ToastService,
} from '@ih/app/client/shared/services';
import {
  Group,
  GroupMember,
  GroupPermission,
  GroupRole,
  GroupRolePermission,
  OrganisationMember,
} from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { produce } from 'immer';
import { catchError, tap } from 'rxjs';

import {
  FindAllGroupPermissionsAction,
  FindGroupOrganisationProjectRoleAction,
  FindGroupProjectRoleAction,
  SetGroupPermissionsAction,
  SetGroupRoleAction,
} from '../../actions/groups';
import { SetGroupRoleError, SetGroupRoleSuccess } from '../../effects/groups';

export interface GroupAuthorisationStateModel {
  groupId: string;
  groupRole: GroupRole | null;
  permissions: string[];
}

const defaults: GroupAuthorisationStateModel = {
  groupId: '',
  groupRole: null,
  permissions: [],
};

@State<GroupAuthorisationStateModel>({
  name: 'groupAuthorisation',
  defaults,
})
@Injectable()
export class GroupAuthorisationState {
  @Selector()
  public static permissions(state: GroupAuthorisationStateModel): string[] {
    return state.permissions;
  }

  public static groupRole(
    state: GroupAuthorisationStateModel,
  ): GroupRole | null {
    return state.groupRole;
  }

  constructor(
    private readonly toastService: ToastService,
    private readonly store: Store,
    private readonly groupsService: GroupsService,
    private readonly organisationsService: OrganisationsService,
  ) {}

  @Action(SetGroupRoleAction)
  public SetGroupRoleAction(
    ctx: StateContext<GroupAuthorisationStateModel>,
    { userId, groupId }: SetGroupRoleAction,
  ) {
    produce(ctx.getState(), (draft: GroupAuthorisationStateModel) => {
      (draft.groupId = ''),
        ((draft.groupRole = null), (draft.permissions = []));
    });
    return ctx.dispatch(new FindGroupProjectRoleAction(userId, groupId));
  }

  @Action(FindGroupProjectRoleAction)
  public FindGroupProjectRoleAction(
    ctx: StateContext<GroupAuthorisationStateModel>,
    { userId, groupId }: FindGroupProjectRoleAction,
  ) {
    return this.groupsService
      .findAllGroupMembersWithRolePermissions(userId, groupId)
      .pipe(
        tap((groupMembers: GroupMember[]) => {
          if (groupMembers.length > 0) {
            ctx.dispatch(new FindAllGroupPermissionsAction(groupMembers));
          } else {
            ctx.dispatch(
              new SetGroupRoleError({
                message: 'Not Part of Group',
              } as Error),
            );
          }
        }),
        catchError((error) => ctx.dispatch(new SetGroupRoleError(error))),
      );
  }

  @Action(FindAllGroupPermissionsAction)
  public FindAllGroupPermissionsAction(
    ctx: StateContext<GroupAuthorisationStateModel>,
    { groupMembers }: FindAllGroupPermissionsAction,
  ) {
    return this.groupsService
      .findAllGroupPermissions({
        where: {},
      })
      .pipe(
        tap((groupPermissions: GroupPermission[]) => {
          if (groupPermissions.length > 0) {
            ctx.dispatch(
              new SetGroupPermissionsAction(groupMembers, groupPermissions),
            );
          } else {
            ctx.dispatch(
              new SetGroupRoleError({
                message: 'Not Part of Group',
              } as Error),
            );
          }
        }),
        catchError((error) => ctx.dispatch(new SetGroupRoleError(error))),
      );
  }

  @Action(SetGroupPermissionsAction)
  public SetGroupPermissionsAction(
    ctx: StateContext<GroupAuthorisationStateModel>,
    { groupMembers, permissions }: SetGroupPermissionsAction,
  ) {
    const allGroupRolePermissions: GroupRolePermission[] = groupMembers[0]
      ?.groupRole?.groupRolePermissions
      ? groupMembers[0]?.groupRole?.groupRolePermissions
      : [];

    const groupRolePermissions: string[] = [];

    let role: GroupRole | null = null;

    // if (groupRolePermissions?.length > 0) {
    allGroupRolePermissions.forEach((rolePermission: GroupRolePermission) => {
      // find group permission with permissionRole id

      const permission = permissions.find(
        (permission) => permission.id === rolePermission.groupPermissionId,
      );

      if (permission) groupRolePermissions.push(permission.identifier);
    });

    if (groupMembers[0]?.groupRole)
      role = { ...groupMembers[0]?.groupRole, groupRolePermissions: [] };

    return ctx.setState(
      produce(ctx.getState(), (draft: GroupAuthorisationStateModel) => {
        (draft.groupId = groupMembers[0].groupId),
          (draft.groupRole = role),
          (draft.permissions = groupRolePermissions);
      }),
    );
  }

  @Action(SetGroupRoleSuccess)
  public SetGroupRoleSuccess(
    ctx: StateContext<GroupAuthorisationStateModel>,
    { result, groupId }: SetGroupRoleSuccess,
  ) {
    const groupRolePermissions: GroupRolePermission[] =
      result[0].groupRole?.groupRolePermissions;

    // const organisationRolePermissions: OrganisationRolePermission[] =
    //   result[0].organisationRole?.organisationRolePermissions;

    const permissions: string[] = [];

    let role: GroupRole | null = null;

    // if (groupRolePermissions?.length > 0) {
    groupRolePermissions.forEach((rolePermission) => {
      if (rolePermission.groupPermissions) {
        if (rolePermission.groupPermissions[0])
          permissions.push(rolePermission.groupPermissions[0].identifier);
      }
    });

    role = { ...result[0].groupRole, groupRolePermissions: [] };
    /*} else if (organisationRolePermissions?.length > 0) {
      organisationRolePermissions.forEach((rolePermission) => {
        if (rolePermission.organisationPermissions) {
          if (rolePermission.organisationPermissions[0])
            permissions.push(
              rolePermission.organisationPermissions[0].identifier
            );
        }
      });
      role = { ...result[0].organisationRole, organisationRolePermissions: [] };
    }*/

    return ctx.setState(
      produce(ctx.getState(), (draft: GroupAuthorisationStateModel) => {
        (draft.groupId = groupId),
          (draft.groupRole = role),
          (draft.permissions = permissions);
      }),
    );
  }

  @Action(SetGroupRoleError)
  public SetGroupRoleError(
    ctx: StateContext<GroupAuthorisationStateModel>,
    { error }: SetGroupRoleError,
  ) {
    this.toastService.showError('Failed to set group role');
    console.error('Failed to set group role: ', error.message);
  }

  @Action(FindGroupOrganisationProjectRoleAction)
  public FindGroupOrganisationProjectRoleAction(
    ctx: StateContext<GroupAuthorisationStateModel>,
    { userId, groupId }: FindGroupOrganisationProjectRoleAction,
  ) {
    const group = this.store.selectSnapshot<Group | null>(GroupState.group);

    return this.organisationsService
      .findAllOrganisationMembersWithRolePermissions(
        userId,
        group?.organisationId || '',
      )
      .pipe(
        tap((organisationMembers: OrganisationMember[]) => {
          if (organisationMembers.length > 0) {
            ctx.dispatch(new SetGroupRoleSuccess(organisationMembers, groupId));
          } else {
            ctx.dispatch(
              new SetGroupRoleError({
                message: 'Not Part of Group',
              } as Error),
            );
          }
        }),
        catchError((error) => ctx.dispatch(new SetGroupRoleError(error))),
      );
  }
}
