import { Injectable } from '@angular/core';
import { Operation } from '@apollo/client/core';
import { db } from '@ih/app/client/shared/db';
import JSZip from 'jszip';
import { jobLinkTracker } from '@ih/app/client/shared/utils';
import write_blob from 'capacitor-blob-writer';
import { Device } from '@capacitor/device';
import { Directory } from '@capacitor/filesystem';

import {
  Job, JobStatus
} from '@ih/app/client/shared/interfaces';
import { GraphQLError } from 'graphql';
import moment from 'moment';

enum deviceType  {
  android = 'android',
  ios = 'ios',
  web = 'web'
}

/**
 * Monitors all queries to graphql during the runtime of the app and stores data in history. 
 */
@Injectable({ providedIn: 'root' })
export class JobHistoryService {


  // There wont be any duplicates, cap the history at 500
  async add(payload: Job) {
    // TODO : Make this a user defined setting
    const MAX_CACHE_SIZE = 500;
    const queriesCache = db.queriesCache;
    await queriesCache.count().then(async (count) => {
      if (count >= MAX_CACHE_SIZE) {
          const elementsToRemove = count - MAX_CACHE_SIZE + 1; 
          await queriesCache.orderBy(':jobId').limit(elementsToRemove).delete();
      }

      await queriesCache.add(payload);
  });
      
  }

  /**
   * Removes a job from the history based on its id
   * @param jobId 
   */
  remove(jobId: string) : void {
    db.queriesCache.delete(jobId);
  }

  //Might want to make this more flexible in the future
  /**
   * Marks a job as successful in the history
   * @param id 
   */
  jobSucceededUpdate(id : string) {
    db.queriesCache.update(id, { status: JobStatus.success });
  }

  jobFailedUpdate(id : string,error : GraphQLError[]) {
    db.queriesCache.update(id, { status: JobStatus.failed , error});
  }


  /**
   * Returns all jobs that are currently stored in the history
   * @returns 
   */
  async getJobHistory(): Promise<Job[]> {
    return db.queriesCache.toArray().then((jobs: Job[]) => {
      return jobs;
    });
  }


  /**
   * Extracts all media ids from the variables of pending jobs in the history
   * Will console log any errors that occur during the process
   * @updated 04/04/2024 - 15:07:23
   * @returns string[] 
   */
   async processMedia() : Promise<string[]> {
    const mediaIds : string[] = [];
    const jobs = await db.queriesCache.toArray();
    jobs.filter(job => job.status == JobStatus.pending).forEach(async job => {
      const op = JSON.parse(job.operation) as Operation;
      const data = op.variables['rows'][0].columns;
      for (const obj of data) {
        const value : string = obj.value.replace(/^'|'$/g, '');
        if(value.trim().startsWith('offlineMediaTrackId:')) {
          const mediaId = value.split(':')[1];
          try {
            mediaIds.push(mediaId);
          }
          catch(e) {
            console.error(e);
          }
        }
      }
    }
  )
    return mediaIds;
  }

  /**
   * Attempts to automatically execute all pending jobs in the history.
   * Note : This does not upload the media, this must be done separately with the MediaQueriesSyncService
   * @updated 04/04/2024 - 14:45:38
   * @returns boolean
   */
  async autoSync(): Promise<boolean>{
    const jobs = await db.queriesCache.toArray();
    let flag = true;
    jobs.filter(job => job.status == JobStatus.pending).forEach(async job => {
      const op = JSON.parse(job.operation) as Operation;
      if(job.status == JobStatus.pending)
        if(!await jobLinkTracker.retry(op)) flag = false;
    })

    return flag;
  }


  /**
   * Returns all operations that are currently stored in the history
   * @returns 
   */
  async getOperationsFromJobs() : Promise<Operation[]> {
    const jobs: Job[] = await db.queriesCache.toArray();
    const operations: Operation[] = jobs.map(job => JSON.parse(job.operation));
    return operations;
  }


  /**
   * Retries a job that has failed and returns a boolean indicating success or failure.
   * NOTE: This does not handle media uploads, only data.
   * @updated 02/04/2024 - 11:48:44
   * @param op Operation to retry
   * @returns 
   */
  async retry(op : Operation) : Promise<boolean>{
    if(!op) return false;
      return await jobLinkTracker.retry(op);
  }


  /**
   * Returns a specific job from the history based on its id
   * @param id 
   * @returns 
   */
  async getJobFromHistory(id: string): Promise<Job | undefined> {
    const job = db.queriesCache.get(id);
    if(job) return job;
    else return undefined;
  } 

  /**
   * Returns the number of jobs that are currently stored in the history
   * @updated 17/04/2024 - 08:39:30
   * @returns 
   */
  async isEmpty() : Promise<boolean> {
    const jobs = await db.queriesCache.toArray();
    let count = 0;

    jobs.filter(job => job.status == JobStatus.pending).forEach(async (job) => {
      if(job.status == JobStatus.pending)
        count++;
      })
    
    return count === 0;
    }
  


  /**
   * Extracts the data from the queue of operations, including media and assembles it in a zip file.
   * @updated 04/04/2024 - 13:08:02
   * @returns Blob
   */
   async processData(queue : Job[]) : Promise<Blob>{
        const zip = new JSZip()
        let mediaZip : JSZip | null = null;
        const operations = queue.map(job => JSON.parse(job.operation));
        for (let index = 0; index < operations.length; index++) {
         const op = operations[index];
         
         if (op.operationName === "InsertDataFromForm") {
             const columnData = op.variables['rows'][0].columns;
             for (const obj of columnData) {
                 const value: string = obj.value.replace(/^'|'$/g, '');
                 if (value.trim().startsWith('offlineMediaTrackId:')) {
                     if(!mediaZip) mediaZip = new JSZip();

                     const mediaId = value.split(':')[1];
                 
                     const fileData = await db.trackedMedia.get(mediaId);
                     if (fileData) {
                         for (const file of fileData.files) {
                            mediaZip.file(file.name, file);
                         }
                     }
                 }
             }
             const data = {
                 data: op.variables.rows,
                 projectId: op.variables.projectId,
                 submitterEmail: op.variables.submitterEmail,
                 formName: op.variables.formName,
             }
             const jsonData = JSON.stringify(data, null, 4);
             zip.file(`data_${index}.json`, jsonData);
             if(mediaZip) {
              zip.file(`media_${index}.zip`,await mediaZip.generateAsync({ type: "blob" }));
             }
         }
     }
   
     return await zip.generateAsync({ type: "blob" });
   }   
    /**
   * Dumps the data from all form submissions of the specified queue to a zip file and stores it on the user's device with a prompt.
   * @updated 20/03/2024 - 08:23:11
   * @param queue The queue of operations that are to be dumped
   */
    async dumpData(queue : Job[]) : Promise<void> {
      if(queue.length === 0) {
        console.error('Calling dumpData with no data in queue')
        return;
      }
      this.processData(queue).then(async (blob) => {
      const device = (await Device.getInfo()).platform;
      if(device === 'web'){
        const url = URL.createObjectURL(blob);
        const anchorElement = document.createElement('a');
    
        anchorElement.href = url;
        const timestamp = new Date();
        const date =  moment(timestamp).format('D:MMMM:YYYY')
        const time = moment(timestamp).format('HH-mm-ss a');
        const timestampString = `${date}  ${time}`;
        anchorElement.download = `${timestampString}.zip`;
    
        document.body.appendChild(anchorElement);
    
        anchorElement.click();
    
        URL.revokeObjectURL(url);
        document.body.removeChild(anchorElement);
      }
      else{
        const timestamp = new Date();
        const date =  moment(timestamp).format('D MMMM YYYY')
        const time = moment(timestamp).format('HH-mm-ss a');
        const timestampString = `${date}  ${time}`;
        let directory : Directory;

        if(device === deviceType.android)
           directory = Directory.Documents;
        else
          directory = Directory.Data;

        await write_blob({
          path: `${timestampString}.zip`,
          blob: blob,
          directory: directory,
          fast_mode : true,
        });
      }
 
    }
  )}

}


export const  jobHistoryService = new JobHistoryService();
