import { Injectable } from '@angular/core';
import { FetchResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import {
  FindAllOrganisationPermissionsRequest,
  OrganisationMember,
  OrganisationPermission,
} from '@ih/app/shared/apis/interfaces';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable, take } from 'rxjs';

@Injectable()
export class OrganisationsService {
  constructor(private readonly apollo: Apollo, private readonly store: Store) {}

  findAllOrganisationMembersWithRolePermissions(
    userId: string,
    organisationId: string
  ): Observable<OrganisationMember[]> {
    type ResultType = {
      findAllOrganisationMembers: OrganisationMember[] | null;
    };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllOrganisationMembers(
        $userId: String!
        $organisationId: String!
      ) {
        findAllOrganisationMembers(
          request: {
            where: { userId: $userId, organisationId: $organisationId }
          }
        ) {
          role
          organisationId
          organisationRole {
            id
            name
            organisationRolePermissions {
              id
              organisationPermissionId
            }
          }
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          userId,
          organisationId,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }

            // if(result.data?.findAllProjectMembers.length > 0)
            return result.data?.findAllOrganisationMembers || [];
            // else
            //   return findUniqueProject()
          }
        )
      );
  }

  findAllOrganisationPermissions(
    request: FindAllOrganisationPermissionsRequest
  ): Observable<OrganisationPermission[]> {
    type ResultType = {
      findAllOrganisationPermissions: OrganisationPermission[] | null;
    };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllOrganisationPermissions(
        $request: FindAllOrganisationPermissionsRequest!
      ) {
        findAllOrganisationPermissions(request: $request) {
          id
          identifier
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findAllOrganisationPermissions || [];
          }
        )
      );
  }
}
