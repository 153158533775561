import { Injectable } from '@angular/core';
import { User } from '@angular/fire/auth';
import { AuthenticationService } from '@ih/app/client/shared/services';
import { SetMe } from '@ih/app/client/shared/states';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { produce } from 'immer';
import { tap } from 'rxjs';
import {
  AuthStateAction,
  LoggedInAction,
  LoggedOutAction,
  LogoutAction,
  LogoutSuccess,
  SetRegisteringAction,
  SetUserAction,
} from '../actions';

export interface AuthenticationStateModel {
  user: User | null;
  registering: boolean;
}

const defaults: AuthenticationStateModel = {
  user: null,
  registering: false,
};

@State<AuthenticationStateModel>({
  name: 'authentication',
  defaults,
})
@Injectable()
export class AuthenticationState {
  @Selector()
  public static user(state: AuthenticationStateModel): User | null {
    return state.user;
  }

  constructor(private readonly authenticationService: AuthenticationService) { }

  @Action(SetUserAction)
  public setUserAction(
    ctx: StateContext<AuthenticationStateModel>,
    { user }: SetUserAction,
  ) {
    return ctx.setState(
      produce(ctx.getState(), (state: AuthenticationStateModel) => {
        state.user = user;
      }),
    );
  }

  @Action(SetRegisteringAction)
  public setRegisteringAction(
    ctx: StateContext<AuthenticationStateModel>,
    { registering }: SetRegisteringAction,
  ) {
    return ctx.setState(
      produce(ctx.getState(), (state: AuthenticationStateModel) => {
        state.registering = registering;
      }),
    );
  }

  @Action(AuthStateAction)
  public authStateAction(ctx: StateContext<AuthenticationStateModel>) {
    return this.authenticationService.authState().pipe(
      tap((user: User | null) => {
        if (user) ctx.dispatch(new LoggedInAction(user));
        else ctx.dispatch(new LoggedOutAction());
      }),
    );
  }

  @Action(LogoutAction)
  public async logoutAction(ctx: StateContext<AuthenticationStateModel>) {
    await this.authenticationService.logout();
    return ctx.dispatch(new LogoutSuccess());
  }

  @Action(LogoutSuccess)
  public async logoutSuccess(ctx: StateContext<AuthenticationStateModel>) {
    ctx.dispatch(new Navigate(['/']));
  }

  @Action(LoggedInAction)
  public loggedInAction(
    ctx: StateContext<AuthenticationStateModel>,
    { user }: LoggedInAction,
  ) {
    ctx.dispatch(new SetUserAction(user));
  }

  @Action(LoggedOutAction)
  public loggedOutAction(ctx: StateContext<AuthenticationStateModel>) {
    ctx.dispatch([new SetUserAction(null), new SetMe(null)]);
  }
}
