import { Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage} from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

enum PushNotificationStatus {
  GRANTED = 'granted',
  PROMPT = 'prompt',
  DENIED = 'denied'
}

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  private currentToken = new BehaviorSubject<string | null>(null);
  public currentToken$ = this.currentToken.asObservable();
  vapidKey = ''

  init(key : string){
    this.vapidKey = key;
    if (Capacitor.isNativePlatform()) {
      this.requestNativePermission();
    } else {
      this.registerServiceWorker();
      this.requestWebPermission();
      this.listen();
    }
  }

  async requestNativePermission() {
    try {
      let permStatus = await PushNotifications.checkPermissions();
      let statusReceived : PushNotificationStatus = permStatus.receive as PushNotificationStatus;

      if (statusReceived === PushNotificationStatus.PROMPT) {
        permStatus = await PushNotifications.requestPermissions();
        statusReceived = permStatus.receive as PushNotificationStatus;
      }

      if (statusReceived !== PushNotificationStatus.GRANTED) {
        return;
      }

      await PushNotifications.register();

      await PushNotifications.addListener('registration', token => {
        console.info('Registration token: ', token.value);
      });

      await PushNotifications.addListener('registrationError', err => {
        // console.error('Registration error: ', err.error);
      });

      await PushNotifications.addListener('pushNotificationReceived', notification => {
        // console.log('Push notification received: ', JSON.stringify(notification));
      });

      await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        // console.log('Push notification action performed', notification.actionId, notification.inputValue);
      });
    } catch (error) {
      console.error('Error requesting permission: ', error)
    }
  }

  async requestWebPermission() {
    const statusReceived: PushNotificationStatus = Notification.permission as PushNotificationStatus;
    if (statusReceived === PushNotificationStatus.DENIED) {
      return;
    }

    if (statusReceived === PushNotificationStatus.GRANTED) {
      this.getToken();
      return;
    }

    Notification.requestPermission().then((permission) => {
      if (permission !== PushNotificationStatus.GRANTED) {
        return;
      }
      this.getToken();
    });

  }

  async registerServiceWorker() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((err) => {
          console.error(err)
          console.error('Service Worker registration failed:', err);
        });
    }
  }

  async getToken() {
    try {
      const messaging = getMessaging();
      const currentToken = await getToken(messaging, { vapidKey: this.vapidKey });
      if (currentToken) {
        console.info('Client registration token: ', currentToken);
        this.currentToken.next(currentToken);
      } else {
        console.error('No registration token available. Request permission to generate one.');
      }
    } catch (err) {
      console.error('An error occurred while retrieving token.', err);
    }
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      const statusReceived: PushNotificationStatus = Notification.permission as PushNotificationStatus;
      if (statusReceived === PushNotificationStatus.GRANTED) {
        const notificationTitle = payload.notification?.title as string;
        const notificationOptions = {
          body: payload.notification?.body,
          icon: payload.notification?.image,
        };

        new Notification(notificationTitle, notificationOptions);

      } else {
        console.error('Notification permission not granted.');
      }
    });
  }

  sendFormSubmissionNotification(token: string, title: string, body: string) {
    throw new Error("Method not implemented.");
  }

  sendFormCreationNotification(token: string, title: string, body: string) {
    throw new Error("Method not implemented.");
  }

  sendProjectCreatedNotification(token: string, title: string, body: string) {
    throw new Error("Method not implemented.");
  }

  sendApiKeyCreatedNotification(token: string, title: string, body: string) {
    throw new Error("Method not implemented.");
  }

  sendNotification(notificationType: string) {
    this.currentToken$.subscribe(token => {
      if (token) {
        switch (notificationType) {
          case 'form-submission':
            this.sendFormSubmissionNotification(token, 'Form Submission', 'Your form has been successfully submitted.');
            break;
          case 'form-creation':
            this.sendFormSubmissionNotification(token, 'Form Created', 'Your form has been successfully created.');
            break;
          case 'api-key-created':
            this.sendFormSubmissionNotification(token, 'Api Key Created', 'Your api key has been successfully created.');
            break;
          case 'project-created':
            this.sendFormSubmissionNotification(token, 'Project Created', 'Your project has been successfully created.');
            break;
          default:
            console.error('Unknown notification type');
        }
      } else {
        console.error('No token available');
      }
    });
  }
}