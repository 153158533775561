import { Injectable } from '@angular/core';
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ToastService } from '@ih/app/client/shared/services';
import {
  OrganisationMember,
  OrganisationRole,
} from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, take, tap } from 'rxjs';

import {
  FindAllDefaultRoles,
  FindAllOrganisationMembers,
  FindAllOrganisationRoles,
  ResetOrganisationUsersList,
  SetOrganisationMembers,
} from '../actions';
import {
  FindAllDefaultRolesError,
  FindAllDefaultRolesSuccess,
  FindAllOrganisationMembersError,
  FindAllOrganisationMembersSuccess,
  FindAllOrganisationRolesError,
  FindAllOrganisationRolesSuccess,
} from '../effects';

export interface OrganisationUsersListStateModel {
  organisationMembers: OrganisationMember[];
  organisationRoles: OrganisationRole[];
}

@State<OrganisationUsersListStateModel>({
  name: 'organisationMembersList',
  defaults: {
    organisationMembers: [],
    organisationRoles: [],
  },
})
@Injectable()
export class OrganisationUsersListState {
  constructor(
    private readonly store: Store,
    private readonly apollo: Apollo,
    private readonly toastService: ToastService,
  ) {}

  @Selector()
  public static getState(state: OrganisationUsersListStateModel) {
    return state;
  }

  @Selector()
  public static organisationMembers(
    state: OrganisationUsersListStateModel,
  ): OrganisationMember[] {
    return state.organisationMembers;
  }

  @Action(FindAllOrganisationMembers)
  public findAllOrganisationMembers(
    ctx: StateContext<OrganisationUsersListStateModel>,
  ) {
    const organisationId = this.store.selectSnapshot<string>(
      (state) => state.organisation.organisation.id,
    );

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllOrganisationMembers($organisationId: String!) {
        findAllOrganisationMembers(
          request: { where: { organisationId: $organisationId } }
        ) {
          role
          id
          user {
            id
            username
            displayName
            email
            photoURL
          }
          organisationRoleId
        }
      }
    `;

    const postsQuery = this.apollo.watchQuery<{
      findAllOrganisationMembers: OrganisationMember[];
    }>({
      query,
      variables: {
        organisationId,
      },
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });
    postsQuery.refetch();
    return postsQuery.valueChanges.pipe(
      take(1),
      tap(
        (
          result: ApolloQueryResult<{
            findAllOrganisationMembers: OrganisationMember[];
          }>,
        ) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              variables: {
                organisationId,
              },
              data: result.data,
            });
          }
          ctx.dispatch(new FindAllOrganisationMembersSuccess(result));
        },
      ),
      catchError((error) =>
        ctx.dispatch(new FindAllOrganisationMembersError(error)),
      ),
    );
  }

  @Action(FindAllOrganisationMembersSuccess)
  public findAllOrganisationMembersSuccess(
    ctx: StateContext<OrganisationUsersListStateModel>,
    { result }: FindAllOrganisationMembersSuccess,
  ) {
    const organisationMembers: OrganisationMember[] = (
      result?.data?.findAllOrganisationMembers || []
    )
      .filter(function (organisationMember) {
        if (organisationMember) {
          return true;
        }
        return false;
      }, [])
      .sort((a, b) => {
        if (a == null || b == null) return 0;
        if (a.user == null || b.user == null) return 0;
        return a.user.displayName.localeCompare(b.user.displayName);
      });

    ctx.dispatch(new FindAllDefaultRoles(organisationMembers));
  }

  @Action(FindAllOrganisationMembersError)
  public findAllOrganisationMembersError(
    ctx: StateContext<OrganisationUsersListStateModel>,
    { error }: FindAllOrganisationMembersError,
  ) {
    this.toastService.showError('Failed to fetch users');
    console.error('Failed to fetch users', error.message);
  }

  @Action(FindAllDefaultRoles)
  public findAllDefaultRoles(
    ctx: StateContext<OrganisationUsersListStateModel>,
    { organisationMembers }: FindAllDefaultRoles,
  ) {
    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query {
        findAllOrganisationRoles(request: { where: {}, limit: 3 }) {
          id
          name
        }
      }
    `;

    const postsQuery = this.apollo.watchQuery<{
      findAllOrganisationRoles: OrganisationRole[];
    }>({
      query,
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });
    postsQuery.refetch();
    return postsQuery.valueChanges.pipe(
      take(1),
      tap(
        (
          result: ApolloQueryResult<{
            findAllOrganisationRoles: OrganisationRole[];
          }>,
        ) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          ctx.dispatch(
            new FindAllDefaultRolesSuccess(result, organisationMembers),
          );
        },
      ),
      catchError((error) => ctx.dispatch(new FindAllDefaultRolesError(error))),
    );
  }

  @Action(FindAllDefaultRolesSuccess)
  public findAllDefaultRolesSuccess(
    ctx: StateContext<OrganisationUsersListStateModel>,
    { result, members }: FindAllDefaultRolesSuccess,
  ) {
    const defaultRoles: OrganisationRole[] =
      result?.data?.findAllOrganisationRoles || [];

    ctx.dispatch(new FindAllOrganisationRoles(members, defaultRoles));
  }

  @Action(FindAllDefaultRolesError)
  public findAllDefaultRolesError(
    ctx: StateContext<OrganisationUsersListStateModel>,
    { error }: FindAllDefaultRolesError,
  ) {
    this.toastService.showError('Failed to fetch roles');
    console.error('Failed to fetch roles', error.message);
  }

  @Action(FindAllOrganisationRoles)
  public findAllOrganisationRoles(
    ctx: StateContext<OrganisationUsersListStateModel>,
    { organisationMembers, defaultRoles }: FindAllOrganisationRoles,
  ) {
    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const organisationId = this.store.selectSnapshot<string>(
      (state) => state.organisation.organisation.id,
    );

    const query = gql`
      query FindAllOrganisationRoles($organisationId: String!) {
        findAllOrganisationRoles(
          request: {
            where: { organisationId: $organisationId, deleted: false }
          }
        ) {
          id
          name
        }
      }
    `;

    const postsQuery = this.apollo.watchQuery<{
      findAllOrganisationRoles: OrganisationRole[];
    }>({
      query,
      variables: {
        organisationId,
      },
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });
    postsQuery.refetch();
    return postsQuery.valueChanges.pipe(
      take(1),
      tap(
        (
          result: ApolloQueryResult<{
            findAllOrganisationRoles: OrganisationRole[];
          }>,
        ) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          ctx.dispatch(
            new FindAllOrganisationRolesSuccess(
              result,
              organisationMembers,
              defaultRoles,
            ),
          );
        },
      ),
      catchError((error) =>
        ctx.dispatch(new FindAllOrganisationRolesError(error)),
      ),
    );
  }

  @Action(FindAllOrganisationRolesSuccess)
  public findAllOrganisationRolesSuccess(
    ctx: StateContext<OrganisationUsersListStateModel>,
    { result, members, defaultRoles }: FindAllOrganisationRolesSuccess,
  ) {
    const organisationRoles: OrganisationRole[] = defaultRoles.concat(
      result?.data?.findAllOrganisationRoles,
    );
    ctx.dispatch(new SetOrganisationMembers(members, organisationRoles));
  }

  @Action(FindAllOrganisationRolesError)
  public findAllOrganisationRolesError(
    ctx: StateContext<OrganisationUsersListStateModel>,
    { error }: FindAllOrganisationRolesError,
  ) {
    this.toastService.showError('Failed to fetch roles');
    console.error('Failed to fetch roles', error.message);
  }

  @Action(SetOrganisationMembers)
  public setOrganisationMembers(
    ctx: StateContext<OrganisationUsersListStateModel>,
    { organisationMembers, organisationRoles }: SetOrganisationMembers,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.organisationMembers = organisationMembers.map((member) => {
          return {
            ...member,
            organisationRole: organisationRoles.filter(
              (role) => role.id === member.organisationRoleId,
            )[0],
          };
        });

        draft.organisationRoles = organisationRoles;
      }),
    );
  }

  @Action(ResetOrganisationUsersList)
  public resetOrganisationUsersList(
    ctx: StateContext<OrganisationUsersListStateModel>,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.organisationMembers = [];
        draft.organisationRoles = [];
      }),
    );
  }
}
