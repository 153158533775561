import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import {
  CopyProjectRequest,
  FindAllProjectMembersRequest,
  FindAllProjectPermissionsRequest,
  FindUniqueProjectRequest,
  Project,
  ProjectMember,
  ProjectPermission,
  FindUniqueProjectRoleRequest,
  ProjectRole,
} from '@ih/app/shared/apis/interfaces';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable, take } from 'rxjs';

@Injectable()
export class ProjectsService {
  constructor(private readonly apollo: Apollo, private readonly store: Store) {}

  getBaseProjectRole(request : FindUniqueProjectRoleRequest) : Observable<ProjectRole>{
    type ResultType = { findUniqueProjectRole: ProjectRole};
    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query findUniqueProjectRole($name: String, $projectId: String) {
        findUniqueProjectRole(request: {
          name: $name
          projectId: $projectId
        }) {
          id
          name
          projectRolePermissions {
            id
            projectPermissionId
          }
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          name: request.name,
          projectId: request.projectId,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map((result: ApolloQueryResult<ResultType>) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
              variables: {
                name: request.name,
                projectId: request.projectId,
              },
            });
          }
          return result.data?.findUniqueProjectRole || null;
        })
      );
  }
  //ProjectMembers
  findAllProjectMembersWithRolePermissions(
    request: FindAllProjectMembersRequest
  ): Observable<ProjectMember[]> {
    type ResultType = { findAllProjectMembers: ProjectMember[] | null };
    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllProjectMembers($request: FindAllProjectMembersRequest!) {
        findAllProjectMembers(request: $request) {
          role
          projectId
          projectRole {
            id
            name
            projectRolePermissions {
              id
              projectPermissionId
            }
          }
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findAllProjectMembers || [];
          }
        )
      );
  }

  //ProjectMembers
  findAllProjectPermissions(
    request: FindAllProjectPermissionsRequest
  ): Observable<ProjectPermission[]> {
    type ResultType = { findAllProjectPermissions: ProjectPermission[] | null };
    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllProjectPermissions(
        $request: FindAllProjectPermissionsRequest!
      ) {
        findAllProjectPermissions(request: $request) {
          id
          identifier
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findAllProjectPermissions || [];
          }
        )
      );
  }

  // Projects
  findUniqueProject(
    request: FindUniqueProjectRequest
  ): Observable<Project | null> {
    type ResultType = { findUniqueProject: Project | null };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query findUniqueProject($request: FindUniqueProjectRequest!) {
        findUniqueProject(request: $request) {
          name
          id
        }
      }
    `;
    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findUniqueProject || null;
          }
        )
      );
  }

  copyProject(request: CopyProjectRequest): Observable<any> {
    const query = gql`
      mutation copyProject($request: CopyProjectRequest!) {
        copyProject(request: $request){
          id
          name
        }
      }
    `;

    return this.apollo.mutate({
      mutation: query,
      variables: {
        request,
      },
    });
  }

}
