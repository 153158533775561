import { Injectable } from '@angular/core';
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ToastService } from '@ih/app/client/shared/services';
import { User } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, take, tap } from 'rxjs';
import { GetMe, GetMeError, GetMeSuccess, SetMe } from '../actions';

export interface MeStateModel {
  me: User | null;
  errors: Error[];
}

const defaults: MeStateModel = {
  me: null,
  errors: [],
};

@State<MeStateModel>({
  name: 'me',
  defaults,
})
@Injectable()
export class MeState {
  @Selector()
  public static me(state: MeStateModel): User | null {
    return state.me;
  }

  constructor(
    private readonly toastService: ToastService,
    private readonly apollo: Apollo,
    private readonly store: Store
  ) {}


  /**
   * @description Gets current user and sets MeStateModel.me to it
   * @updated 18/03/2024 - 09:22:11
   * @public
   * @onSuccess Dispatch {@link getMeSuccess}
   * @onFail Dispatch {@link getMeError}
   */
  @Action(GetMe)
  public getMe(ctx: StateContext<MeStateModel>) {
    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'cache-and-network';
    }

    const query = gql`
      query {
        me {
          id
          username
          displayName
          email
          photoURL
        }
      }
    `;

    let takes = 2;
    const cachedData = this.apollo.client.cache.readQuery({
      query: query,
    });

    if (!cachedData || !networkStatus) {
      takes = 1;
    }

    return this.apollo
      .watchQuery<{ me: User }>({
        query,
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(takes),
        tap((result: ApolloQueryResult<{ me: User }>) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          ctx.dispatch(new GetMeSuccess(result));
        }),
        catchError((error) => ctx.dispatch(new GetMeError(error)))
      );
  }


  /**
   *  Dispatches SetMe action to set state
   * @updated 18/03/2024 - 09:22:11
   * @public
   * @dispatch {@link setMe}
   */
  @Action(GetMeSuccess)
  public async getMeSuccess(
    ctx: StateContext<MeStateModel>,
    { result }: GetMeSuccess
  ) {
    const me = (result?.data?.me || null) as User;
    ctx.dispatch(new SetMe(me));
  }

  /**
   * Displays a failure toast and logs it to console
   * @updated 18/03/2024 - 09:22:11
   * @public
   */
  @Action(GetMeError)
  public async getMeError(
    ctx: StateContext<MeStateModel>,
    { error }: GetMeError
  ) {
    this.toastService.showError('Failed to fetch current user');
    console.error('Failed to fetch current user: ', error.message);
  }

  /**
   * Sets MeStateModel.me to the provided user
   * @updated 18/03/2024 - 09:22:11
   * @public
   */
  @Action(SetMe)
  public setMe(ctx: StateContext<MeStateModel>, { me }: SetMe) {
    ctx.setState(
      produce((draft) => {
        draft.me = me;
      })
    );
  }
}
