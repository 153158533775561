import { Injectable } from '@angular/core';
import {
  OrganisationsService,
  ToastService,
} from '@ih/app/client/shared/services';
import {
  OrganisationMember,
  OrganisationPermission,
  OrganisationRole,
  OrganisationRolePermission,
} from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import { catchError, tap } from 'rxjs';

import {
  FindAllOrganisationPermissionsAction,
  SetOrganisationPermissionsAction,
  SetOrganisationRoleAction,
} from '../../actions/organisations';
import {
  SetOrganisationRoleError,
  SetOrganisationRoleSuccess,
} from '../../effects/organisations';

export interface OrganisationAuthorisationStateModel {
  organisationId: string;
  organisationRole: OrganisationRole | null;
  permissions: string[];
}

const defaults: OrganisationAuthorisationStateModel = {
  organisationId: '',
  organisationRole: null,
  permissions: [],
};

@State<OrganisationAuthorisationStateModel>({
  name: 'organisationAuthorisation',
  defaults,
})
@Injectable()
export class OrganisationAuthorisationState {
  @Selector()
  public static permissions(
    state: OrganisationAuthorisationStateModel,
  ): string[] {
    return state.permissions;
  }

  public static organisationRole(
    state: OrganisationAuthorisationStateModel,
  ): OrganisationRole | null {
    return state.organisationRole;
  }

  constructor(
    private readonly toastService: ToastService,
    private readonly organisationsService: OrganisationsService,
  ) {}

  @Action(SetOrganisationRoleAction)
  public SetOrganisationRoleAction(
    ctx: StateContext<OrganisationAuthorisationStateModel>,
    { userId, organisationId }: SetOrganisationRoleAction,
  ) {
    return this.organisationsService
      .findAllOrganisationMembersWithRolePermissions(userId, organisationId)
      .pipe(
        tap((organisationMembers: OrganisationMember[]) => {
          if (organisationMembers.length > 0) {
            ctx.dispatch(
              new FindAllOrganisationPermissionsAction(organisationMembers),
            );
          } else {
            ctx.dispatch(
              new SetOrganisationRoleError({
                message: 'Not Part of Organisation',
              } as Error),
            );
          }
        }),
        catchError((error) =>
          ctx.dispatch(new SetOrganisationRoleError(error)),
        ),
      );
  }

  @Action(FindAllOrganisationPermissionsAction)
  public FindAllOrganisationPermissionsAction(
    ctx: StateContext<OrganisationAuthorisationStateModel>,
    { organisationMembers }: FindAllOrganisationPermissionsAction,
  ) {
    return this.organisationsService
      .findAllOrganisationPermissions({
        where: {},
      })
      .pipe(
        tap((organisationPermissions: OrganisationPermission[]) => {
          if (organisationPermissions.length > 0) {
            ctx.dispatch(
              new SetOrganisationPermissionsAction(
                organisationMembers,
                organisationPermissions,
              ),
            );
          } else {
            ctx.dispatch(
              new SetOrganisationRoleError({
                message: 'Not Part of Organisation',
              } as Error),
            );
          }
        }),
        catchError((error) =>
          ctx.dispatch(new SetOrganisationRoleError(error)),
        ),
      );
  }

  @Action(SetOrganisationPermissionsAction)
  public SetOrganisationPermissionsAction(
    ctx: StateContext<OrganisationAuthorisationStateModel>,
    { organisationMembers, permissions }: SetOrganisationPermissionsAction,
  ) {
    const allOrganisationRolePermissions: OrganisationRolePermission[] =
      organisationMembers[0]?.organisationRole?.organisationRolePermissions
        ? organisationMembers[0]?.organisationRole?.organisationRolePermissions
        : [];

    const organisationRolePermissions: string[] = [];

    let role: OrganisationRole | null = null;

    // if (organisationRolePermissions?.length > 0) {
    allOrganisationRolePermissions.forEach(
      (rolePermission: OrganisationRolePermission) => {
        // find organisation permission with permissionRole id

        const permission = permissions.find(
          (permission) =>
            permission.id === rolePermission.organisationPermissionId,
        );

        if (permission) organisationRolePermissions.push(permission.identifier);
      },
    );

    if (organisationMembers[0]?.organisationRole)
      role = {
        ...organisationMembers[0]?.organisationRole,
        organisationRolePermissions: [],
      };

    return ctx.setState(
      produce(ctx.getState(), (draft: OrganisationAuthorisationStateModel) => {
        draft.organisationId = organisationMembers[0].organisationId;
        draft.organisationRole = role;
        draft.permissions = organisationRolePermissions;
      }),
    );
  }

  @Action(SetOrganisationRoleSuccess)
  public SetOrganisationRoleSuccess(
    ctx: StateContext<OrganisationAuthorisationStateModel>,
    { result, organisationId }: SetOrganisationRoleSuccess,
  ) {
    const rolePermissions: OrganisationRolePermission[] =
      result.data.findAllOrganisationMembers[0].organisationRole
        .organisationRolePermissions;

    const permissions: string[] = [];

    rolePermissions.forEach((rolePermission) => {
      if (rolePermission.organisationPermissions) {
        if (rolePermission.organisationPermissions[0])
          permissions.push(
            rolePermission.organisationPermissions[0].identifier,
          );
      }
    });

    return ctx.setState(
      produce(ctx.getState(), (draft: OrganisationAuthorisationStateModel) => {
        draft.organisationId = organisationId;
        draft.organisationRole =
          result.data.findAllOrganisationMembers[0].organisationRole;
        draft.permissions = permissions;
      }),
    );
  }

  @Action(SetOrganisationRoleError)
  public SetOrganisationRoleError(
    ctx: StateContext<OrganisationAuthorisationStateModel>,
    { error }: SetOrganisationRoleError,
  ) {
    this.toastService.showError('Failed to set organisation role');
    console.error('Failed to set organisation role: ', error.message);
  }
}
