import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, SecurityContext } from '@angular/core';
import {
  ScreenTrackingService,
  UserTrackingService,
  getAnalytics,
  provideAnalytics,
} from '@angular/fire/analytics';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  getAuth,
  browserLocalPersistence,
  initializeAuth,
  provideAuth,
} from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Capacitor } from '@capacitor/core';
import {
  AuthenticationService,
  GroupsService,
  OrganisationsService,
  ProjectsService,
} from '@ih/app/client/shared/services';
import {
  AuthenticationState,
  GroupAuthorisationState,
  NetworkState,
  OrganisationAuthorisationState,
  ProjectAuthorisationState,
  UiState,
  RedirectState,
  MeState
} from '@ih/app/client/shared/states';
import { AppClientShellFeatureModule } from '@ih/app/client/shell/feature';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
// import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { ApolloModule } from 'apollo-angular';
import { MarkdownModule } from 'ngx-markdown';
import { MomentModule } from 'ngx-moment';
import { environment } from '../environments/environment';
import { stateCacheKeys } from '../shared';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
@NgModule({
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: browserLocalPersistence,
        });
      } else {
        return getAuth();
      }
    }),
    provideStorage(() => getStorage()),
    provideAnalytics(() => getAnalytics()),
    AuthenticationService,
    ScreenTrackingService,
    UserTrackingService,
    ProjectsService,
    GroupsService,
    OrganisationsService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  declarations: [AppComponent],
  imports: [
    GraphQLModule,
    ApolloModule,
    BrowserAnimationsModule,
    BrowserModule,
    IonicModule.forRoot(),
    HttpClientModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
      sanitize: SecurityContext.NONE,
    }),
    MomentModule.forRoot(),
    NgxsModule.forRoot(
      [
        AuthenticationState,
        ProjectAuthorisationState,
        GroupAuthorisationState,
        OrganisationAuthorisationState,
        NetworkState,
        UiState,
        RedirectState,
        MeState,
      ],
      {
        developmentMode: !environment.production,
      },
    ),
    NgxsActionsExecutingModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    // NgxsLoggerPluginModule.forRoot({
    //   disabled: environment.production,
    // }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({ key: stateCacheKeys }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.pwa,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    AppClientShellFeatureModule,
  ],

  bootstrap: [AppComponent],
})
export class AppModule { }
