import { Injectable } from '@angular/core';
import { ProjectState } from '@ih/app/client/project/data-access';
import {
  GroupsService,
  OrganisationsService,
  ProjectsService,
  ToastService,
} from '@ih/app/client/shared/services';
import {
  GroupMember,
  OrganisationMember,
  Project,
  ProjectMember,
  ProjectPermission,
  ProjectRole,
  ProjectRolePermission,
  RoleType,
} from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { produce } from 'immer';
import { catchError, tap } from 'rxjs';

import {
  FindAllProjectPermissionsAction,
  FindProjectGroupProjectRoleAction,
  FindProjectOrganisationProjectRoleAction,
  FindProjectRoleAction,
  SetProjectPermissionsAction,
  SetProjectRoleAction,
} from '../../actions/projects';
import {
  SetProjectRoleError,
  SetProjectRoleSuccess,
} from '../../effects/projects';

export interface ProjectAuthorisationStateModel {
  projectId: string;
  projectRole: ProjectRole | null;
  permissions: string[];
}

const defaults: ProjectAuthorisationStateModel = {
  projectId: '',
  projectRole: null,
  permissions: [],
};

@State<ProjectAuthorisationStateModel>({
  name: 'projectAuthorisation',
  defaults,
})
@Injectable()
export class ProjectAuthorisationState {
  @Selector()
  public static permissions(state: ProjectAuthorisationStateModel): string[] {
    return state.permissions;
  }

  @Selector()
  public static projectRole(
    state: ProjectAuthorisationStateModel,
  ): ProjectRole | null {
    return state.projectRole;
  }

  constructor(
    private readonly toastService: ToastService,
    private readonly store: Store,
    private readonly groupsService: GroupsService,
    private readonly projectsService: ProjectsService,
    private readonly organisationsService: OrganisationsService,
  ) {}

  @Action(SetProjectRoleAction)
  public SetProjectRoleAction(
    ctx: StateContext<ProjectAuthorisationStateModel>,
    { userId, projectId,template }: SetProjectRoleAction,
  ) {
    produce(ctx.getState(), (draft: ProjectAuthorisationStateModel) => {
      draft.projectId = '';
      draft.projectRole = null;
      draft.permissions = [];
    });
    return ctx.dispatch(new FindProjectRoleAction(userId, projectId,template));
  }

  @Action(FindProjectRoleAction)
  public FindProjectRoleAction(
    ctx: StateContext<ProjectAuthorisationStateModel>,
    { userId, projectId,template }: FindProjectRoleAction,
  ) {
    return this.projectsService
      .findAllProjectMembersWithRolePermissions({
        where: { userId, projectId },
      })
      .pipe(
        tap(async (projectMembers: ProjectMember[]) => {
          if (projectMembers.length > 0) {
            ctx.dispatch(new FindAllProjectPermissionsAction(projectMembers));
            return;
          } else 
            if (!template)  
            {
              ctx.dispatch(
                new SetProjectRoleError({
                  message: 'Not Part of Project',
                } as Error),
              );
              return;
            }
            // If project is a template, assign viewer role
            this.projectsService.getBaseProjectRole({name : RoleType.VIEWER,projectId: null}).pipe(
              tap((role) => {
                const viewerMember : ProjectMember = {
                  id : userId,
                  userId : userId,
                  projectId,
                  role: RoleType.VIEWER,
                  projectRole: role,
                  deleted : false,
                  favourite : false,
                  created : new Date().toString(),
                  updated : new Date().toString(),
                }
                ctx.dispatch(new FindAllProjectPermissionsAction([viewerMember]));
              }),
              catchError((error) => ctx.dispatch(new SetProjectRoleError(error))),
            ).subscribe()
            
        }),
        catchError((error) => ctx.dispatch(new SetProjectRoleError(error))),
      );
  }

  @Action(FindAllProjectPermissionsAction)
  public FindAllProjectPermissionsAction(
    ctx: StateContext<ProjectAuthorisationStateModel>,
    { projectMembers }: FindAllProjectPermissionsAction,
  ) {
    return this.projectsService
      .findAllProjectPermissions({
        where: {},
      })
      .pipe(
        tap((projectPermissions: ProjectPermission[]) => {
          if (projectPermissions.length > 0) {
            ctx.dispatch(
              new SetProjectPermissionsAction(
                projectMembers,
                projectPermissions,
              ),
            );
          } else {
            ctx.dispatch(
              new SetProjectRoleError({
                message: 'Not Part of Project',
              } as Error),
            );
          }
        }),
        catchError((error) => ctx.dispatch(new SetProjectRoleError(error))),
      );
  }

  @Action(SetProjectPermissionsAction)
  public SetProjectPermissionsAction(
    ctx: StateContext<ProjectAuthorisationStateModel>,
    { projectMembers, permissions }: SetProjectPermissionsAction,
  ) {
    const allProjectRolePermissions: ProjectRolePermission[] = projectMembers[0]
      ?.projectRole?.projectRolePermissions
      ? //conditional, not assertion
      projectMembers[0]?.projectRole?.projectRolePermissions
      : [];

    const projectRolePermissions: string[] = [];

    let role: ProjectRole | null = null;

    // if (projectRolePermissions?.length > 0) {
    allProjectRolePermissions.forEach(
      (rolePermission: ProjectRolePermission) => {
        // find project permission with permissionRole id

        const permission = permissions.find(
          (permission) => permission.id === rolePermission.projectPermissionId,
        );

        if (permission) projectRolePermissions.push(permission.identifier);
      },
    );

    if (projectMembers[0]?.projectRole)
      role = { ...projectMembers[0]?.projectRole, projectRolePermissions: [] };

    return ctx.setState(
      produce(ctx.getState(), (draft: ProjectAuthorisationStateModel) => {
        draft.projectId = projectMembers[0].projectId;
        draft.projectRole = role;
        draft.permissions = projectRolePermissions;
      }),
    );
  }

  @Action(SetProjectRoleSuccess)
  public SetProjectRoleSuccess(
    ctx: StateContext<ProjectAuthorisationStateModel>,
    { result, projectId }: SetProjectRoleSuccess,
  ) {
    const projectRolePermissions: ProjectRolePermission[] =
      result[0].projectRole?.projectRolePermissions;

    // const groupRolePermissions: GroupRolePermission[] =
    //   result[0].groupRole?.groupRolePermissions;

    // const organisationRolePermissions: OrganisationRolePermission[] =
    //   result[0].organisationRole?.organisationRolePermissions;

    const permissions: string[] = [];

    let role: ProjectRole | null = null;

    // if (projectRolePermissions?.length > 0) {
    projectRolePermissions.forEach((rolePermission: ProjectRolePermission) => {
      if (rolePermission.projectPermissions) {
        if (rolePermission.projectPermissions[0])
          permissions.push(rolePermission.projectPermissions[0].identifier);
      }
    });
    role = { ...result[0].projectRole, projectRolePermissions: [] };
    /*} else if (groupRolePermissions?.length > 0) {
      groupRolePermissions.forEach((rolePermission) => {
        if (rolePermission.groupPermissions) {
          if (rolePermission.groupPermissions[0])
            permissions.push(rolePermission.groupPermissions[0].identifier);
        }
      });

      role = { ...result[0].groupRole, groupRolePermissions: [] };
    } else if (organisationRolePermissions?.length > 0) {
      organisationRolePermissions.forEach((rolePermission) => {
        if (rolePermission.organisationPermissions) {
          if (rolePermission.organisationPermissions[0])
            permissions.push(
              rolePermission.organisationPermissions[0].identifier
            );
        }
      });
      role = { ...result[0].organisationRole, organisationRolePermissions: [] };
    }*/

    return ctx.setState(
      produce(ctx.getState(), (draft: ProjectAuthorisationStateModel) => {
        draft.projectId = projectId;
        draft.projectRole = role;
        draft.permissions = permissions;
      }),
    );
  }

  @Action(SetProjectRoleError)
  public SetProjectRoleError(
    ctx: StateContext<ProjectAuthorisationStateModel>,
    { error }: SetProjectRoleError,
  ) {
    this.toastService.showError('Failed to set project role');
    console.error('Failed to set project role: ', error.message);
    produce(ctx.getState(), (draft: ProjectAuthorisationStateModel) => {
      draft.projectId = '';
      draft.projectRole = null;
      draft.permissions = [];
    });
  }

  @Action(FindProjectGroupProjectRoleAction)
  public FindProjectGroupProjectRoleAction(
    ctx: StateContext<ProjectAuthorisationStateModel>,
    { userId, projectId }: FindProjectGroupProjectRoleAction,
  ) {
    const project = this.store.selectSnapshot<Project | null>(
      ProjectState.project,
    );
    return this.groupsService
      .findAllGroupMembersWithRolePermissions(userId, project?.group?.id || '')
      .pipe(
        tap((groupMembers: GroupMember[]) => {
          if (groupMembers.length > 0) {
            ctx.dispatch(new SetProjectRoleSuccess(groupMembers, projectId));
          } else {
            ctx.dispatch(
              new FindProjectOrganisationProjectRoleAction(userId, projectId),
            );
          }
        }),
        catchError((error) => ctx.dispatch(new SetProjectRoleError(error))),
      );
  }

  @Action(FindProjectOrganisationProjectRoleAction)
  public FindProjectOrganisationProjectRoleAction(
    ctx: StateContext<ProjectAuthorisationStateModel>,
    { userId, projectId }: FindProjectOrganisationProjectRoleAction,
  ) {
    const project = this.store.selectSnapshot<Project | null>(
      ProjectState.project,
    );

    return this.organisationsService
      .findAllOrganisationMembersWithRolePermissions(
        userId,
        project?.organisation?.id || '',
      )
      .pipe(
        tap((organisationMembers: OrganisationMember[]) => {
          if (organisationMembers.length > 0) {
            ctx.dispatch(
              new SetProjectRoleSuccess(organisationMembers, projectId),
            );
          } else {
            ctx.dispatch(
              new SetProjectRoleError({
                message: 'Not Part of Project',
              } as Error),
            );
          }
        }),
        catchError((error) => ctx.dispatch(new SetProjectRoleError(error))),
      );
  }
}
